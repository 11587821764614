<!-- 课程反馈 -->
<template>
  <div>
    <div class="main" style="display: flex;">
      <div style="width: 100%;height: 100%;display: flex;flex-direction: column;width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;">
        <div style="font-size: 26px; font-weight: bold;display:flex">
          <div style="cursor: pointer;padding: 10px 0;">课程反馈</div>
        </div>
       
        <div
          style="height: 60px;background: #fff;display: flex;justify-content: space-between;padding: 0 20px;align-items: center;">
          <el-input placeholder="请输入课程名称" prefix-icon="el-icon-search" v-model="searchStr" style="width: 232px;"
            @keyup.enter.native="getVideoList">
          </el-input>
        </div>
        <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData" style="width: 100%; padding: 20px;"
          >
          <el-table-column prop="name" align="center" label="课程名称"></el-table-column>
          <el-table-column prop="weight" align="center" label="评分"></el-table-column>
          <el-table-column prop="name" align="center" label="留言">
            <template slot-scope="scope">
              <div>我是留言</div>
            </template>
          </el-table-column>
          <el-table-column prop="onlineTime" align="center" label="用户名称">
            <template slot-scope="scope">
              <div style="color:#7E8887">
                我是名称 
              </div>
            </template>
          </el-table-column>
          </el-table-column>
          <el-table-column prop="timeLength" align="center" label="留言时间">
            <template slot-scope="scope">
              <div style="color:#7E8887">
                2021-01-22 23:23 
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding:20px;
          background:#fff;
        ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { questionList } from '../../apis/index'
export default {
  data() {
    return {
      statusList: [//选择列表
        '全部','优秀','删除'
      ],
      courseTitleInd: '',//当前选择的id
      total:50,
      pageIndex:1,
      pageSize:10,
      tableData:[]
    };
  },
  methods:{
    courseTitle(index){
      this.courseTitleInd=index
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val){
      this.pageIndex=val
    },
  }
};
</script>

<style lang='less' scoped>
/deep/ .dialog1 .el-dialog__body {
  height: 85%;
  overflow: auto;
  padding: 0 20px;
}

/deep/ .dialog1 .el-dialog {
  margin: 0 !important;
  float: right;
  height: 100%;
}

.titleLayout {
  display: flex;
  border-radius: 8px 8px 0 0;
  background: #C6E7E2;
  height: 34px;
  line-height: 34px;
}

.currentTitle {
  background: #fff;
  border-radius: 8px 8px 0 0;
  height: 34px;
  line-height: 34px;
}

.main {
  // min-height: 800px;
  // background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
  box-sizing: border-box;
  display: flex;
    flex: 1;
}

// .main>div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
.changeSel {
  padding: 8px 22px;
  background: #2FAFA1;
  border-radius: 50px;
  color: #fff;
}

/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}

.defaultcolor {
  color: #3C4645;
}

.red {
  color: #ff1b0b;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #00A78E;
  background: #00A78E
}

.selectContent {
  color: #00A78E;
}

.cstatus1 {
  color: #FAAD14;
}

.cstatus2 {
  color: #54C97F;
}

.cstatus3 {
  color: #ACB6B5;
}

.hand:hover {
  cursor: pointer;
}

.typeWrap {
  margin: 10px 0;
}

.typeWrap:hover {
  cursor: pointer;
}

.typeWrap>div {
  border: 1px solid #CDD3D2;
  border-right: none;
}

.typeWrap>div:last-child {
  border: 1px solid #CDD3D2;
  border-radius: 0 8px 8px 0;
}

.typeWrap>div:first-child {
  border: 1px solid #CDD3D2;
  border-radius: 8px 0 0 8px;
}

.typeWrap>div.selectLevel {
  border: 1px solid #00A78E;
  color: #00A78E;
}

.line {
  width: 4px;
  height: 13px;
  background: #00A78E;
  border-radius: 50px;
  margin-right: 5px;
}
</style>
